import { mapGetters, mapMutations } from "vuex"
import { SPEAKUP_PAGE_TITLE } from "@/constants"

export default {
  name      : "IssueExport",
  components: {
  },
  data: () => ({
  }),
  mounted() {
    window.addEventListener("afterprint", this.handleAfterPrintPdf)
    window.addEventListener("beforeprint", this.handlePageTitleName)
    window.setTimeout(() => {
      window.print()
    }, 1000)
  },
  beforeDestroy() {
    this.setPrintPdfData(new Object())
    this.setPrintPdfFileName(undefined)
    window.removeEventListener("afterprint", this.handleAfterPrintPdf)
    window.removeEventListener("beforeprint", this.handlePageTitleName)
  },
  computed: {
    ...mapGetters({
      printPdfData    : "exports/printPdfData",
      printPdfFileName: "exports/printPdfFileName"
    })
  },
  methods: {
    ...mapMutations({
      setPrintPdfData    : "exports/setPrintPdfData",
      setPrintPdfFileName: "exports/setPrintPdfFileName"
    }),
    handlePageTitleName() {
      document.title = this.printPdfFileName
    },
    getRowStyles(row) {
      return {
        backgroundColor: row.background || "",
        color          : row.color || "",
        fontWeight     : row.bold ? "bold" : ""
      }
    },
    getCellStyles(table, row, index) {
      return {
        backgroundColor: !row.background && table.style?.background?.[index] ? table.style.background[index] : "",
        fontWeight     : !row.bold && table.style?.bold?.[index] ? "bold" : ""
      }
    },
    getColspan(table, row) {
      const maxColumnCount = table.rows.reduce((max, r) => Math.max(max, r.items.length), 0)
      return maxColumnCount !== row.items.length ? maxColumnCount : 1
    },
    handleAfterPrintPdf() {
      document.title = SPEAKUP_PAGE_TITLE
      this.$router.push({ name: "issue", params: { id: +this.$route.params.id } })
    }
  },
  watch: {
  }
}